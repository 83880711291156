// 35
@font-face {
  font-family: 'Helvetica Neue Thin';
  src: url('/assets/fonts/HelveticaNeueLTStd-Th.woff2') format('woff2'),
      url('/assets/fonts/HelveticaNeueLTStd-Th.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// 45
@font-face {
  font-family: 'Helvetica Neue Light';
  src: url('/assets/fonts/HelveticaNeueLTStd-Lt.woff2') format('woff2'),
      url('/assets/fonts/HelveticaNeueLTStd-Lt.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// 55
@font-face {
  font-family: 'Helvetica Neue Regular';
  src: url('/assets/fonts/HelveticaNeueLTStd-Roman.woff2') format('woff2'),
      url('/assets/fonts/HelveticaNeueLTStd-Roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}