// Importing Reishauer fonts and tailwind after material theme
@use "fonts";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Helvetica Neue Regular", Roboto, sans-serif;
}

// custom loading spinner
.loading-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.loading-spinner-container-background {
  background-color: rgba(0, 0, 0, 0.1);
}
